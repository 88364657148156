import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  FileTypes,
  getFileExtensionFromFilename,
  getFileTypeFromExtension,
  getReadableFileSize,
} from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import Loader from "react-loader";
import loaderOptions from "../../LoadingBar/LoadingBar";
import { TestNote, TestNoteAttachment } from "../../Services/NotesService";
import { ProfileIcon } from "../Icons/ProfileIcon/ProfileIcon";
import { getFileIconName } from "./Note.helpers";
import "./Note.scss";

export const NoteAttachment = ({ attachment, measure }) => {
  const [isImageFileLoading, setIsImageFileLoading] = useState(true);
  const fileType = getFileTypeFromExtension(
    getFileExtensionFromFilename(attachment.file_name)
  );
  const fileIconName = getFileIconName(fileType);
  const fileSizeString = getReadableFileSize(attachment.file_size);

  const isImageFile = fileType === FileTypes.IMAGE;

  const onLoad = () => {
    setIsImageFileLoading(false);
  };

  useEffect(() => {
    if (!isImageFileLoading && measure) {
      measure();
    }
  }, [isImageFileLoading, measure]);

  return (
    <li className="testNote__attachmentItem">
      <a
        className="testNote__attachmentDownloadLink"
        href={attachment.download_link}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesome
          name={fileIconName}
          className="icon"
        />

        {attachment.file_name}
      </a>{" "}
      ({fileSizeString})
      {isImageFile && (
        <div
          className={`testNote__attachmentItem__imageContainer ${
            isImageFileLoading ? "loading" : null
          }`}
        >
          <img
            src={attachment.download_link}
            className={`testNote__attachmentItem__image ${
              isImageFileLoading ? "loading" : null
            }`}
            onLoad={onLoad}
          />
          <Loader
            loaded={!isImageFileLoading}
            options={{ ...loaderOptions, top: "50%" }}
          />
        </div>
      )}
    </li>
  );
};
NoteAttachment.propTypes = {
  attachment: PropTypes.instanceOf(TestNoteAttachment).isRequired,
  measure: PropTypes.func,
};

export const Note = ({ testNote, measure }) => {
  const dateString = getTimeStringFromDate(
    testNote.date,
    config.DATETIME_FORMAT
  );

  return (
    <main className="testNote">
      <aside>
        <ProfileIcon
          username={testNote.user.name}
          fontSize="25px"
        />
      </aside>
      <section className="testNote__body">
        <header className="testNote__byline">
          <span
            className="testNote__author"
            data-hj-suppress
          >
            {testNote.user.name}
          </span>

          {" — "}

          <span className="testNote__date">{dateString}</span>
        </header>

        <p className="testNote__text">{testNote.text}</p>

        {testNote.attachments.length > 0 && (
          <ul className="list-unstyled">
            {testNote.attachments.map((attachment) => {
              return (
                <NoteAttachment
                  attachment={attachment}
                  key={attachment.id}
                  measure={measure}
                />
              );
            })}
          </ul>
        )}
      </section>
    </main>
  );
};

Note.propTypes = {
  testNote: PropTypes.instanceOf(TestNote).isRequired,
  measure: PropTypes.func,
};

export default Note;
